import React from "react"
import 'bootstrap/dist/css/bootstrap.css'
import '../fonts/Muli-Black.ttf'
import LPComponent from "../components/lp-component"


const IndexPage = () => (
  
  <LPComponent></LPComponent>
 
)

export default IndexPage
