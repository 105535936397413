import React from "react"
import HomeHero from "../images/home-hero.png"
import NearbyHero from "../images/nearby.png"
import SecurityHero from "../images/security.png"
import Sitelogo from "../images/site-logo.png"
import { Link } from "gatsby"
import CreatePin from "./createpin-homepage"
import Nearby from "./nearby.js"
import firebase from "../../src/components/auth/fire-base.js"
import Auth from '../Auth'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import Helmet from "react-helmet"
import { withPrefix } from "gatsby"
import withLocation from "./wigets/withLocation"

const auth = new Auth();


class LPComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isShowFreeDialog:false,
      user:  null,
    }
    this.searchBar = React.createRef()
    this.displayError = React.createRef()
  }

  clearState() {
    this.displayError.current.hidden = true
    this.searchBar.current.style.border = ""
  }

  async componentDidMount(){
    const user = await auth.user();
    this.setState({user, user});

    const { referralCode } = this.props.search;
    if(referralCode){
      localStorage.setItem('referralCode', referralCode);
    }
  }

  search = async event => {
    event.preventDefault()
    if (this.searchBar.current.value.trim() !== "") {
      await firebase
        .firestore()
        .collection("pins")
        .doc(this.searchBar.current.value)
        .get()
        .then(doc => {
          if (doc.exists) {
            window.location.href =
              "/search?pincode=" + this.searchBar.current.value
            this.displayError.current.hidden = true
            this.searchBar.current.style.border = ""
          } else {
            this.displayError.current.hidden = false
            this.searchBar.current.style.border = "3px solid red"
            this.displayError.current.innerHTML =
              "PIN not found, please try again"
            setTimeout(() => {
              this.clearState()
            }, 3000)
          }
        })
        .catch(err => {
          console.log(err)
        })
    } else {
      this.displayError.current.innerHTML = "Please enter a value"
      this.searchBar.current.style.border = "3px solid red"
      this.displayError.current.hidden = false
      setTimeout(() => {
        this.clearState()
      }, 3000)
    }
  }

  openVideo() {
    window.open(
      "https://www.youtube.com/watch?v=o98KUU6VS4I",
      "MsgWindow",
      "width=600,height=400"
    )
  }

 

  render() {
    console.log(this.state.user)
    return (
      <div className="d-flex">
         <Helmet>
          <script src={withPrefix('invite1.js')} type="text/javascript" />
        </Helmet>
        <div className="container-fluid">
          <div className="">
            <div className="row">
              <div className="">
                <div className="col-md-6 pull-left ">
                  <div className="home-content-wrapper">
                    <div className="row">
                      <div className="col-md-6 col-6">
                        <img className="home-logo" src={Sitelogo} />
                      </div>
                      <div className="col-md-6 col-6">
                        <Link
                              to="/signup"
                              style={{
                                color: "inherit",
                                textDecoration: "inherit",
                              }}
                            >
                          <button className="home-sign-up desktop-only">
                              Get Started for Free
                          </button>
                        </Link>

                        <Link
                              to={this.state.user?"/home":"/signin"}
                              style={{
                                color: "inherit",
                                textDecoration: "inherit",
                              }}
                            >
                          <button className="home-sign-in desktop-only">
                            {
                              this.state.user ? 
                                "Home":"Sign In"
                            }
                          </button>
                        </Link>

                        <Link
                              to="/signup"
                              style={{
                                color: "inherit",
                                textDecoration: "inherit",
                              }}
                            >
                          <button className="home-sign-up phone-only">
                              Get Started
                          </button>
                        </Link>
                      </div>
                    </div>
                    <div className="row">
                      <div className="hero-header-image col-md-6 order-md-2">
                        <img src={HomeHero} />
                      </div>
                      <div className="col-md-6 order-md-1">
                        <h2 className="hero-header">
                          <span className="anything">Wireless</span> Sharing
                        </h2>
                        <h3 className="hero-subheader">
                          With Cloudpin, you can connect with anyone nearby.
                        </h3>
                        <form onSubmit={this.search} className="search-form">
                          <input
                            id="search-bar"
                            type="text"
                            className="home-pin-input"
                            placeholder="Enter Pin Code Here"
                            ref={this.searchBar}
                          ></input>
                          <p
                            style={{ color: "red" }}
                            hidden
                            id="error"
                            ref={this.displayError}
                          ></p>
                        </form>
                        <p className="watch-video">
                          Watch the Video&nbsp;
                          <svg
                            onClick={() => this.openVideo()}
                            style={{ cursor: "pointer" }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="14"
                            viewBox="0 0 22 14"
                          >
                            <path
                              fill="#B0B5BB"
                              fillRule="evenodd"
                              d="M20.804 12.082l-4.55-3.033v1.73c0 1.437-1.164 2.602-2.6 2.602H3.257c-1.436 0-2.6-1.165-2.6-2.601V2.98c0-1.435 1.164-2.6 2.6-2.6h10.399c1.435 0 2.6 1.165 2.6 2.6v1.735l4.549-3.033c.359 0 .65.29.65.65v9.098c0 .36-.292.65-.65.65zm-5.85-9.1c0-.719-.582-1.3-1.3-1.3H3.257c-.718 0-1.3.581-1.3 1.3v7.797c0 .719.582 1.3 1.3 1.3h10.399c.717 0 1.3-.581 1.3-1.3V2.981zm5.2.696l-3.9 2.6v1.209l3.9 2.599V3.678z"
                            />
                          </svg>
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <h5 className="home-subheader" id="how-it-works">How it Works</h5>
                      </div>
                      <div className="col-md-4">
                        <div className="row">
                          <div className="work-icon col-4">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="77"
                              height="77"
                              viewBox="0 0 77 77"
                            >
                              <g
                                fill="#102542"
                                fillRule="evenodd"
                                transform="translate(.656)"
                              >
                                <circle
                                  cx="38.103"
                                  cy="38.103"
                                  r="38.103"
                                  fillOpacity=".197"
                                />
                                <path d="M47.194 26.24h-5.537c-.527-1.583-1.977-2.637-3.69-2.637-1.714 0-3.164 1.054-3.691 2.636h-5.537c-1.45 0-2.636 1.187-2.636 2.637v21.09c0 1.45 1.186 2.637 2.636 2.637h18.455c1.45 0 2.636-1.186 2.636-2.636V28.876c0-1.45-1.186-2.637-2.636-2.637zm-9.227 0c.79 0 1.318.527 1.318 1.317 0 .791-.528 1.319-1.318 1.319-.791 0-1.319-.528-1.319-1.319 0-.79.528-1.318 1.319-1.318zm9.227 23.727H28.739V28.876h2.637v3.954h13.181v-3.954h2.637v21.09z" />
                              </g>
                            </svg>
                          </div>
                          <div className="col-md-12 col-8">
                            <h6 className="work-subheader"> Upload or Paste</h6>
                            <p className="work-content">
                              your files, links, photos, videos and text
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="row">
                          <div className="work-icon col-4">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="77"
                              height="77"
                              viewBox="0 0 77 77"
                            >
                              <g
                                fill="#D91E36"
                                fillRule="evenodd"
                                transform="translate(.177)"
                              >
                                <circle
                                  cx="38.103"
                                  cy="38.103"
                                  r="38.103"
                                  fillOpacity=".236"
                                />
                                <path d="M48.463 39.503c0-.42.14-.84.14-1.4 0-.56 0-.98-.14-1.4l2.94-2.38c.28-.28.28-.56.14-.84l-2.8-4.9c-.14-.14-.42-.28-.84-.14l-3.5 1.4c-.7-.56-1.54-.98-2.38-1.4l-.56-3.64c.14-.42-.28-.7-.56-.7h-5.6c-.28 0-.7.28-.7.56l-.56 3.78c-.84.28-1.54.84-2.38 1.4l-3.36-1.4c-.42-.14-.7 0-.98.28l-2.8 4.9c-.14.14 0 .56.28.84l2.94 2.24c0 .42-.14.84-.14 1.4 0 .56 0 .98.14 1.4l-2.94 2.38c-.28.28-.28.56-.14.84l2.8 4.9c.14.14.42.28.84.14l3.5-1.4c.7.56 1.54.98 2.38 1.4l.56 3.64c0 .28.28.56.7.56h5.6c.28 0 .7-.28.7-.56l.56-3.64c.84-.42 1.68-.84 2.38-1.4l3.5 1.4c.28.14.7 0 .84-.28l2.8-4.9c.14-.28.14-.7-.14-.84l-3.22-2.24zm-10.36 3.5c-2.66 0-4.9-2.24-4.9-4.9s2.24-4.9 4.9-4.9 4.9 2.24 4.9 4.9-2.24 4.9-4.9 4.9z" />
                              </g>
                            </svg>
                          </div>
                          <div className="col-md-12 col-8">
                            <h6 className="work-subheader"> Choose</h6>
                            <p className="work-content">
                              your unique pin code, a password, and location
                              settings
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="row">
                          <div className="work-icon col-4">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="77"
                              height="77"
                              viewBox="0 0 77 77"
                            >
                              <g
                                fill="#6D72C3"
                                fillRule="evenodd"
                                transform="translate(.699 .558)"
                              >
                                <circle
                                  cx="38.103"
                                  cy="38.103"
                                  r="38.103"
                                  fillOpacity=".249"
                                />
                                <path d="M38.103 23.603c-5.655 0-10.15 4.495-10.15 10.15 0 7.54 10.15 18.85 10.15 18.85s10.15-11.31 10.15-18.85c0-5.655-4.495-10.15-10.15-10.15zm0 13.775c-2.03 0-3.625-1.595-3.625-3.625 0-2.03 1.595-3.625 3.625-3.625 2.03 0 3.625 1.595 3.625 3.625 0 2.03-1.595 3.625-3.625 3.625z" />
                              </g>
                            </svg>
                          </div>
                          <div className="col-md-12 col-8">
                            <h6 className="work-subheader"> Pin it</h6>
                            <p className="work-content">
                              Share your code or direct nearby people to
                              cloudpin.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6 pull-left"
                  style={{ backgroundColor: "#f5f5f5" }}
                  id="trynow"
                >
                  <div className="home-content-wrapper">
                    <div className="desktop-only try-spacer" />
                    <h5 className="home-subheader"> Try it now (24 hours)</h5>
                    <h6 className="try-subheader">
                      {" "}
                      Paste your content, share your (editable) unique code{" "}
                    </h6>
                      <CreatePin editMode={false}></CreatePin>
                    <div className="row" style={{ backgroundColor: "#f5f5f5" }}>
                      <div className="col-md-6 col-6">
                        <h5 className="home-subheader">Shared Nearby</h5>
                      </div>
                      <div className="col-md-6 col-6">
                        <h6 className="more-link">
                          <Link
                            to="/nearby"
                            style={{
                              color: "inherit",
                              textDecoration: "inherit",
                            }}
                          >
                            More &gt;
                          </Link>
                        </h6>
                      </div>
                      <Nearby limit={true}></Nearby>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row hero-section">
              <div className="home-content-wrapper">
                <div className="col-sm-12 col-md-6 pull-left ">
                  <div className="">
                    <div className="row">
                      <div className="col-md-12 col-12 col-sm-12 hero-wrapper">
                        <img className="hero-image" src={NearbyHero} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-12 pull-left ">
                  <div className="">
                    <div className="row">
                      <div className="col-md-12 col-12 col-sm-12 header-text">
                        <h2 className="hero-header">
                          Connect with the people around you
                        </h2>
                        <h3 className="hero-subheader">
                          Wirelessly broadcast content to other users in the
                          immediate area, regardless of device.
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row hero-section">
              <div className="home-content-wrapper">
                <div className="col-md-6 col-sm-12 pull-left order-md-2 ">
                  <div className="">
                    <div className="row">
                      <div className="col-md-12 col-12 col-sm-12 header-text">
                        <h2 className="hero-header">
                          Privacy first means your content is safe
                        </h2>
                        <h3 className="hero-subheader">
                          By default your content is deleted after 24 hours. We
                          use Google Firebase, so your content encryped at rest
                          and in transit.{" "}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 pull-left order-md-1 ">
                  <div className="">
                    <div className="row">
                      <div className="col-md-12 col-12 col-sm-12 hero-wrapper">
                        <img className="hero-image" src={SecurityHero} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 hero-section">
                <div className="col-md-12 col-sm-12 pull-left .video-container ">
                  <div className="video-container">
                    <iframe
                      src="https://www.youtube.com/embed/o98KUU6VS4I"
                      frameBorder="0"
                      allowFullScreen
                      className="video"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{backgroundColor:'#20202010', paddingTop: 50, paddingBottom: 50,}}>
            <div className="container mb-5">
              <div className="row">
                <div className="col-12 col-md-6">
                    <div className="card"  style={{width: '80%', margin: 'auto', minWidth: 400, backgroundColor:'white', borderRadius: 4, boxShadow:'0 0 50px 1px #3030307f'}}>
                        <div className="card-body bg-white p-5">
                            <div className="card-title">
                              <h3>Standard</h3>
                            </div>
                            <div style={{textAlign:'center', fontSize: 70, fontWeight:'bold'}}>Free</div>
                            <div style={{marginTop: 20, marginBottom: 20,}}>
                              <div style={{display:'flex', marginTop: 10}}>
                                <div><CheckCircleOutlineIcon  style={{marginRight:10, color:'green'}}/> </div>
                                <div>Auto generated pin codes</div>
                              </div>
                              <div style={{display:'flex', marginTop: 10}}>
                                <div><CheckCircleOutlineIcon  style={{marginRight:10, color:'green'}}/> </div>
                                <div>Auto expriation after 24 hours</div>
                              </div>
                              <div style={{display:'flex', marginTop: 10}}>
                                <div><CheckCircleOutlineIcon  style={{marginRight:10, color:'green'}}/> </div>
                                <div>Share up to 2000MB file</div>
                              </div>
                            </div>
                            <div style={{display:'flex', justifyContent:'center', alignItems:'center', marginTop: 50}}>
                                <a href="#trynow">
                                  <button 
                                    style={{padding: '15px 30px',
                                          backgroundColor:'grey',
                                          border:'none',
                                          outline: 'none',
                                          borderRadius: 5,
                                          boxShadow:'0 0 3px 1px #30303030',
                                          color:'white',
                                          fontWeight:'bold',
                                          textDecoration:'none'
                                      }}>
                                    TRY NOW
                                  </button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6">
                <div className="card"  style={{width: '80%', margin: 'auto', minWidth: 400, backgroundColor:'white', borderRadius: 4, boxShadow:'0 0 50px 1px #3030307f'}}>
                        <div className="card-body bg-white p-5">
                            <div className="card-title">
                              <h3>Pro</h3>
                            </div>
                            <div style={{display: 'flex', alignItems:'flex-end', justifyContent:'center'}}>
                              <AttachMoneyIcon style={{fontSize: 40, marginBottom: 20,}} />
                              <div style={{textAlign:'center', fontSize: 70, fontWeight:'bold'}}>3.99</div>
                              <span  style={{fontSize: 40, paddingBottom: 10}}>/mon</span>
                            </div>
                            <div style={{marginTop: 20, marginBottom: 20,}}>
                              <div style={{display:'flex', marginTop: 10}}>
                                <div><CheckCircleOutlineIcon  style={{marginRight:10, color:'green'}}/> </div>
                                <div>Custom pin codes</div>
                              </div>
                              <div style={{display:'flex', marginTop: 10}}>
                                <div><CheckCircleOutlineIcon  style={{marginRight:10, color:'green'}}/> </div>
                                <div>Share to our location</div>
                              </div>
                              <div style={{display:'flex', marginTop: 10}}>
                                <div><CheckCircleOutlineIcon  style={{marginRight:10, color:'green'}}/> </div>
                                <div>Share files larger than</div>
                              </div>
                              <div style={{display:'flex', marginTop: 10}}>
                                <div><CheckCircleOutlineIcon  style={{marginRight:10, color:'green'}}/> </div>
                                <div>Password Protected</div>
                              </div>
                              <div style={{display:'flex', marginTop: 10}}>
                                <div><CheckCircleOutlineIcon  style={{marginRight:10, color:'green'}}/> </div>
                                <div>Surveys, Forms &amp; widgets</div>
                              </div>
                            </div>
                            <div style={{display:'flex', justifyContent:'center', alignItems:'center', marginTop: 50}}>
                              <Link to="/signup">
                                <button 
                                  style={{padding: '15px 30px',
                                        backgroundColor:'#e7523a',
                                        border:'none',
                                        outline: 'none',
                                        borderRadius: 5,
                                        boxShadow:'0 0 3px 1px #30303030',
                                        color:'white',
                                        fontWeight:'bold',
                                        textDecoration:'none'
                                    }}>
                                  TRY 14 DAYS FREE
                                </button>
                              </Link>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withLocation(LPComponent)
