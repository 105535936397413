/**
 * Free Trial Didalog
 * 
 * check free trial days and display it if the current user is not Pro.
 */

import React from 'react';
import { Link } from "gatsby"
import {Dialog, DialogTitle, DialogContent, Button, Typography, IconButton, Divider} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import successImage from '../../images/success.png'

const FreeAlertDialog = ({open, handleClose}) => {
    return(
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle className='text-center p-0'>
                <IconButton
                    className='float-left'
                    onClick={handleClose}
                >
                    <CloseIcon fontSize='large'/>
                </IconButton>
                <div style={{paddingTop: 15, marginRight: 30}}> To use this, go pro</div>
            </DialogTitle>
            <Divider/>
            <DialogContent
                className='text-center'
            >
                <img src={successImage} className='mt-3' alt='Success'/>
                <Typography style={{color:'grey'}}>$3.99/mo</Typography>
                <Typography>Custom pin codes</Typography>
                <Typography>Share to your location</Typography>
                <Typography>Share files larger than 200MB</Typography>
                <Typography> Password protected sharing</Typography>
                <Link to='/signup'
                    style={{
                        color: "inherit",
                        textDecoration: "inherit",
                    }}
                >
                   <Button
                        variant='contained'
                        className='w-100 my-3'
                        style={{backgroundColor:'rgb(232, 87, 58)', color:'white'}}
                    >
                        TRY 14 DAYS FREE
                    </Button>
                </Link>
                <Button
                    onClick={handleClose}
                    className='w-100 mt-2 b-7'
                    disabled
                >
                    No credit card required
                </Button>
            </DialogContent>
        </Dialog>
    )
};

export default FreeAlertDialog;
