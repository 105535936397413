import React from "react"
import { FiPaperclip } from "react-icons/fi"
import { FaTrashAlt } from "react-icons/fa"
import Question from "../images/question.png"
import firebase from "./auth/fire-base.js"
import geohash from "ngeohash"
import { ReactTinyLink } from "react-tiny-link"
import ReactPlayer from "react-player"
import FileIcon from "../images/file-icon.png"
import Dropdown from "react-bootstrap/Dropdown"
import Auth from "../Auth";
import FreeAlertDialog from  './dialogs/free-trial-dialog'
import {NotificationManager, NotificationContainer} from 'react-notifications'

const auth = new Auth();


class CreatePin extends React.Component {
  constructor(props) {
    super(props)
    this.handlePinIDChange = this.handlePinIDChange.bind(this)
    this.onPost = this.onPost.bind(this)
    this.storeLocation = this.storeLocation.bind(this)
    this.handleTextChange = this.handleTextChange.bind(this)
    this.handleFileClick = this.handleFileClick.bind(this)
    this.pinError = React.createRef()
    this.pinPost = React.createRef()
    this.pinText = React.createRef()
    this.password = React.createRef()
    this.fileSelector = React.createRef()
    this.showErrorMessage = React.createRef()
    this.streamInputChange = this.streamInputChange.bind(this)
    this.handleClose.bind(this);
  }

  state = {
    pin_id: "",
    current_username: "",
    lat: null,
    long: null,
    geohash: null,
    additionalPostHTML: [],
    image: null,
    fileurl: "",
    progress: 0,
    pinStream: null,
    pic: "",
    name: "",
    errorMessage: "",
    uploading: false,
    isrequirePassword: false,
    user: null,
    isShowFreeDialog: false,
  }

  generateUsername() {
    var one = Math.floor(Math.random() * 9) + 0
    var two = Math.floor(Math.random() * 9) + 0
    var three = Math.floor(Math.random() * 9) + 0
    return "" + one + two + three
  }

  getUserStreams() {
    if (firebase.auth().currentUser) {
      firebase
        .firestore()
        .collection("streams")
        .where(
          "creator",
          "==",
          firebase
            .firestore()
            .collection("users")
            .doc(process.env.GATSBY_DUMMY_USER)
        )
        .orderBy("createdAt", "desc")
        .onSnapshot(snapshot => {
          this.setState({ streams: [] })
          snapshot.forEach(doc => {
            if (doc && doc.data()) {
              this.setState(prevState => {
                const streams = this.state.streams.concat({
                  id: doc.id,
                  name: doc.data().name,
                  createdAt: doc.data().createdAt,
                })
                return { streams }
              })
            }
          })
        })
    }
  }

  getLocation() {
    if (!this.state.lat || !this.state.long || !this.state.geohash) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.storeLocation)
      } else {
        alert("Geolocation is not supported by this browser.")
      }
    }
  }

  storeLocation(position) {
    this.setState({
      lat: position.coords.latitude,
      long: position.coords.longitude,
      geohash: geohash.encode(
        position.coords.latitude,
        position.coords.longitude
      ),
    })
  }

  async componentDidMount() {
  
    const user = await auth.user()

    this.setState({user: user})

    Date.prototype.addHours = function(h) {
      this.setTime(this.getTime() + h * 60 * 60 * 1000)
      return this
    }

    this.setState({ current_username: this.generateUsername() })
    this.getLocation()
    this.generatePinID()

    this.getUserStreams()

    this.setState({
      image: null,
      fileurl: this.props.fileURL ? this.props.fileURL : "",
      progress: 0,
      pinStream: null,
    })

    if (firebase.auth().currentUser) {
      firebase
        .firestore()
        .collection("users")
        .doc(process.env.GATSBY_DUMMY_USER)
        .get()
        .then(doc => {
          this.setState({
            name: doc.data().name,
            x: doc.data().displayPic,
          })
        })
    }
  }

  handlePinIDChange = ({ currentTarget: input }) => {
    this.setState(
      {
        pin_id: input.value,
      },
      () =>
        firebase
          .firestore()
          .collection("pins")
          .doc(this.state.current_username + "-" + input.value)
          .get()
          .then(docSnapshot => {
            if (docSnapshot.exists) {
              this.pinError.current.hidden = false
              this.pinPost.current.disabled = true
              this.pinPost.current.style.backgroundColor = "#f0917f"
            } else {
              this.pinError.current.hidden = true
              this.pinPost.current.disabled = false
              this.pinPost.current.style.backgroundColor = "#e8573a"
            }
          })
    )
  }

  onPost = () => {
    if (
      (this.pinText.current.value.trim() !== "" || this.state.fileurl !== "") &&
      !this.password.current.hidden &&
      this.password.current.value.trim() !== ""
    ) {
      this.processPin()
    } else if (
      (this.pinText.current.value.trim() !== "" || this.state.fileurl !== "") &&
      this.password.current.hidden
    ) {
      this.processPin()
    } else {
      this.showErrorMessage.current.hidden = false
      var error = ""
      if (
        this.pinText.current.value.trim() === "" &&
        this.state.fileurl === "" &&
        !this.password.current.hidden &&
        this.password.current.value.trim() === ""
      ) {
        error =
          "Please enter or upload content, then choose a password or uncheck require password"
      } else if (
        this.pinText.current.value.trim() === "" &&
        this.state.fileurl === ""
      ) {
        error = "Please enter or upload content above."
      } else {
        error = "Please choose a password or uncheck require password."
      }

      this.setState({ errorMessage: error })
      setTimeout(() => {
        this.showErrorMessage.current.hidden = true
      }, 3000)
    }
  }

  processPin() {
    let data = {
      lat: this.state.lat,
      long: this.state.long,
      geohash: this.state.geohash,
      expiry: this.getExpiry(),
      content: {
        text: this.pinText.current.value,
        file: this.state.fileurl,
      },
      stream: this.state.pinStream
        ? firebase
            .firestore()
            .collection("streams")
            .doc(this.state.pinStream)
        : null,
      createdAt: new Date(),
      creator: firebase
        .firestore()
        .collection("users")
        .doc(process.env.GATSBY_DUMMY_USER),
      pincode: this.state.current_username + "-" + this.state.pin_id,
    }

    if (this.password.current !== undefined)
      if (!this.password.current.hidden)
        data["password"] = this.password.current.value

    var pincode = this.state.pin_id
    firebase
      .firestore()
      .collection("pins")
      .doc(this.state.current_username + "-" + this.state.pin_id)
      .set(data)
      .then(() => {
        if (!this.props.editMode) {
          firebase.analytics().logEvent("New Pin Created", { name: "New Pin" })
        }
        this.setState(
          {
            pin_id: "",
          },
          () => {
            if (this.props.editMode === false) {
              window.location.href =
                "/search?pincode=" + this.state.current_username + "-" + pincode
            }
          }
        )
      })
  }

  generatePinID() {
    var pin_id = ""
    const chars = "abcdefghijklmnopqrstuvwxyz1234567890"

    for (let x = 0; x < 6; x++) {
      let i = Math.floor(Math.random() * 36)
      pin_id += chars.charAt(i)
    }

    firebase
      .firestore()
      .collection("pins")
      .doc(pin_id)
      .get()
      .then(docSnapshot => {
        if (docSnapshot.exists) {
          this.generatePinID()
        } else {
          this.setState({
            pin_id: pin_id,
          })
        }
      })
  }

  streamInputChange(event) {
    if (event.keyCode === 13) {
      // Do something

      let streamName = document.getElementById("streamInput").value

      let data = {
        name: streamName,
        createdAt: new Date(),
        creator: firebase
          .firestore()
          .collection("users")
          .doc(process.env.GATSBY_DUMMY_USER),
      }

      firebase
        .firestore()
        .collection("streams")
        .doc(streamName)
        .set(data)
        .then(() => {
          this.setState({
            pinStream: streamName,
          })
        })
    }
  }

  handleTextChange(event) {
    let pinText = event.target.value

    let geturl = new RegExp(
      // Strict regex to get only links with http
      "(^|[ \t\r\n])((ftp|http|https|gopher|mailto|news|nntp|telnet|wais|file|prospero|aim|webcal):(([A-Za-z0-9$_.+!*(),;/?:@&~=-])|%[A-Fa-f0-9]{2}){2,}(#([a-zA-Z0-9][a-zA-Z0-9$_.+!*(),;/?:@&~=%-]*))?([A-Za-z0-9$_+!*();/?:~-]))",
      "g"
      // "([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?",
      // "g"
    )

    let all_urls = pinText.match(geturl)
    if (all_urls && all_urls.length > 0) {
      this.setState({ additionalPostHTML: all_urls })
    } else {
      this.setState({ additionalPostHTML: [] })
    }
  }

  imageError = e => {
    document.getElementById("file-image-div").hidden = true
    document.getElementById("file-download-div").hidden = false
  }

  handleFileClick = e => {

    if (e.target.files[0]) {
      const image = e.target.files[0]

      if((image.size > 50*1024*1024) && !(this.state.user && this.state.user.pro)){
       
          NotificationManager.error("Too much size, go to Pro")
          this.tryFreeTrial();

      }else{

          this.setState({ image: image, fileurl: "", uploading: true }, () => {
            document.getElementById("file-image-div")
              ? (document.getElementById("file-image-div").hidden = false)
              : void 0
            document.getElementById("file-download-div")
              ? (document.getElementById("file-download-div").hidden = true)
              : void 0
            const { image } = this.state
            const uploadTask = firebase
              .storage()
              .ref(`files/${image.name}`)
              .put(image)
            uploadTask.on(
              "state_changed",
              snapshot => {
                // progress function ...
                const progress = Math.round(
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                )
                this.setState({ progress })
              },
              error => {
                // Error function ...
                console.log(error)
              },
              () => {
                // complete function ...
                firebase
                  .storage()
                  .ref("files")
                  .child(image.name)
                  .getDownloadURL()
                  .then(fileurl => {
                    this.setState({ fileurl: fileurl, uploading: false })
                  })
              }
            )
          })
          
      }
    }
  }

  deleteFile = () => {
    this.setState({
      fileurl: "",
      image: null,
      progress: 0,
    })
  }

  getStreamHTML = streamName => {
    return (
      <Dropdown.Item
        key={streamName}
        onClick={() =>
          this.setState({
            pinStream: streamName,
          })
        }
      >
        {streamName}
      </Dropdown.Item>
    )
  }

  getExpiry = () => {
    var e = document.getElementById("pin-time")
    var hours = parseInt(e.options[e.selectedIndex].value)

    if (hours == -1) {
      return null
    } else {
      return new Date().addHours(hours)
    }
  }

  toggle() {
    if(this.state.user && this.state.user.pro ){
      var x = document.getElementById("password")
      if (x.hidden === false) {
        x.hidden = true
      } else {
        x.hidden = false
      }
      this.setState({isrequirePassword:!this.state.isrequirePassword})
    }else{
      this.setState({isShowFreeDialog: true})
    }
   
  }
  
  handleClose = () =>{
    this.setState({isShowFreeDialog: false})
  };

  tryFreeTrial(){
    if(!(this.state.user && this.state.user.pro) ){
      this.setState({isShowFreeDialog: true})
    }
  }

  render() {
    return (
      <div>
        <NotificationContainer />
        <div>
        <FreeAlertDialog 
          open = {this.state.isShowFreeDialog}
          handleClose = {this.handleClose}
        />
          {/* <GotoProDialog open ={true} handleClose={this.closeProDialog} /> */}
          <div className="col-md-12 col-12 pull-left white-bg"  onClick={()=>{this.tryFreeTrial()}}>
            {" "}
            <span className="home-pin-wrapper">
              {this.state.current_username}-
              <input
                id="pinID"
                name="pinID"
                className="home_pinID"
                value={this.state.pin_id}
                type="text"
                maxLength="6"
                onChange={this.handlePinIDChange}
                disabled={!(this.state.user && this.state.user.pro)}
              />
            </span>
            <p
              id="pinError"
              style={{ color: "red" }}
              hidden
              ref={this.pinError}
            >
              Error! Try a different pin ID
            </p>
          </div>

          <textarea
            id="pinText"
            className="homePinText"
            placeholder="  Paste content (or upload using the clip icon below).."
            rows="4"
            cols="44"
            ref={this.pinText}
            defaultValue={
              this.props.editMode === false ? "" : this.props.storedPinText
            }
            onChange={this.handleTextChange}
            style={{ maxHeight: "400px" }}
          ></textarea>

          <div className="col-md-12 white-bg pull-left">
            <div className="upload-file-wrapper">
              <button
                className="attach-file-btn"
                disabled={this.state.uploading}
                onClick={
                  this.fileSelector.current
                    ? () => this.fileSelector.current.click()
                    : null
                }
              >
                Attach&nbsp;&nbsp;
                {/*<LoadingProgress
                  title={"Uploading.."}
                  active={this.state.uploading}
                  total={100}
                  current={this.state.progress}
                  hideProcessingRequest
                  hideTimeRemaining
                />*/}
                <FiPaperclip
                  className="clip"
                  size={20}
                  style={{ cursor: "pointer" }}
                  hidden={this.state.uploading}
                />
              </button>
              <input
                id="file-selector"
                type="file"
                onChange={this.handleFileClick}
                ref={this.fileSelector}
                hidden
              />
            </div>
          </div>

          {this.state.additionalPostHTML.map((val, index) => {
            let additional_html = ""

            if (val.includes("youtube.com/watch")) {
              additional_html = (
                <div className="col text-center" key={"addhtml" + index}>
                  <ReactPlayer url={val} width="100%" />
                  <br />
                </div>
              )
            } else {
              additional_html = (
                <div className="col" key={"addhtml" + index}>
                  <ReactTinyLink
                    cardSize="small"
                    showGraphic={true}
                    maxLine={2}
                    minLine={1}
                    url={val}
                  />
                  <br />
                </div>
              )
            }

            return additional_html
          })}
          {this.state.fileurl ? (
            <div id="file-div" className="col">
              <FaTrashAlt
                id="deleteFile"
                onClick={this.deleteFile}
                className="offset-10"
              />
              <br />
              <br />
              <img
                id="file-image-div"
                src={this.state.fileurl}
                width="30%"
                className="offset-4"
                onError={this.imageError}
              ></img>
              <a
                id="file-download-div"
                target="_blank"
                href={this.state.fileurl}
                className="offset-4"
                download
                hidden
              >
                <div
                  className="col-6 offset-3 text-center"
                  style={{ border: "1px solid grey" }}
                >
                  <img src={FileIcon} width="25%"></img>
                  <br />
                  <span>Download File</span>
                </div>
                <br />
              </a>
            </div>
          ) : (
            ""
          )}
          <div className="row pin-time-row">
            <div className="col-md-6 col-6 pull-left">
              {" "}
              <h6 className="home-pin-title" style={{color: (this.state.user && this.state.user.pro )? 'black':'gray'}}> Pin to current location</h6>
            </div>
            <div className="col-md-6 col-6 pull-left"  >
              {" "}
              <select id="pin-time" disabled={!(this.state.user && this.state.user.pro)} > 
                <option value="-1" >Disabled</option>
                <option value="1">1 Hour</option>
                <option value="6">6 Hours</option>
                <option value="24">24 Hours</option>
              </select>
              {
                !(this.state.user && this.state.user.pro ) &&
                <div 
                  style={{height: 40, width: 120, backgroundColor:'transparant', position:'absolute',right:0}}
                  onClick={()=>{this.tryFreeTrial()}}>
                </div>
              }
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 col-6 pull-left">
              <input
                id="requirepassword"
                type="checkbox"
                checked = {this.state.isrequirePassword}
                onChange={() => this.toggle()}
              ></input>
              <label htmlFor="requirepassword" className="require-pwd-title"  style={{color: (this.state.user && this.state.user.pro )? 'black':'gray'}}>
                &nbsp;Require Password
              </label>
            </div>
            <div className="col-md-6 col-6 pull-left">
              <input
                id="password"
                type="password"
                placeholder="Choose a Password"
                className="home-pwd-input"
                ref={this.password}
                autoComplete="off"
                hidden
              ></input>
            </div>

            <div className="col-md-12 pull-left">
              <button
                className="home-publish"
                id="pinPost"
                onClick={this.onPost}
                ref={this.pinPost}
              >
                {this.props.editMode === false ? "Publish" : "Update"}
              </button>
              <p
                style={{ textAlign: "center", color: "red" }}
                ref={this.showErrorMessage}
                hidden
              >
                {this.state.errorMessage}
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default CreatePin
