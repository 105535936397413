import { Link } from "gatsby"
import React from "react"
import Nopin from "../images/no-nearby-pin.png"
import firebase from "./auth/fire-base.js"
import geohash from "ngeohash"
import NoImage from "../images/group-28.png"
import FileImage from "../images/group-27.png"
import YoutubeIconImage from "../images/group-29.png"

class Nearby extends React.Component {
  constructor(props) {
    super(props)
    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this)
    this.getAuthorName = this.getAuthorName.bind(this)
    this.storeLocation = this.storeLocation.bind(this)
  }

  state = {
    sidebarOpen: false,
    pins: [],
    pinCreators: [],
    lat: null,
    long: null,
    geohash: null,
  }

  componentDidMount() {
    this.getLocation()
  }

  onSetSidebarOpen(open) {
    this.setState({ sidebarOpen: open })
  }

  loadDefault(id) {
    document.getElementById(id).src = FileImage
    document.getElementById(id).onerror = null
  }

  getPinHtml(id, imageSrc, text, authorName, authorImg, password) {
    var link = "/search?pincode=" + id
    if (text.includes("youtube.com/watch")) imageSrc = YoutubeIconImage
    return (
      <div
        className={this.props.limit ? "col-md-4 col-6" : " col-sm-3 col-6"}
        key={id}
        style={this.props.limit ? {} : { padding: "15px" }}
      >
        <Link to={link} style={{ color: "inherit", textDecoration: "inherit" }}>
          <div className="card nearby-card">
            <img
              id={"image-" + id}
              src={imageSrc ? imageSrc : NoImage}
              className="nearby-img"
              onError={() => this.loadDefault("image-" + id)}
              style={{ maxHeight: "130px", minHeight: "130px" }}
            ></img>
            <div className="nearby-card-body">
              <h5 className="nearby-card-title">
                {text !== "" && password === ""
                  ? text.substring(0, 15) + "..."
                  : "Protected"}
              </h5>
              <p className="nearby-card-text">{id}</p>
            </div>
          </div>
        </Link>
      </div>
    )
  }

  getAuthorName(pinID, creator) {
    if (creator) {
      firebase
        .firestore()
        .collection("users")
        .doc(creator.id)
        .get()
        .then(creatorDoc => {
          if (creatorDoc.exists) {
            this.setState(prevState => ({
              pinCreators: {
                ...prevState.pinCreators,
                [pinID]: creatorDoc.data().name,
              },
            }))
          }
        })
    }
  }

  getLocation() {
    if (this.state.lat && this.state.long && this.state.geohash) {
      this.getPins()
    } else {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.storeLocation)
      } else {
        alert("Geolocation is not supported by this browser.")
      }
    }
  }

  storeLocation(position) {
    this.setState(
      {
        lat: position.coords.latitude,
        long: position.coords.longitude,
        geohash: geohash.encode(
          position.coords.latitude,
          position.coords.longitude
        ),
      },
      () => this.getPins()
    )
  }

  getPins() {
    // Calculating the lower and upper bounds for geohash

    // distance in miles
    let distance = 2
    let lat = 0.0144927536231884 // degrees latitude per mile
    let lon = 0.0181818181818182

    let lowerLat = this.state.lat - lat * distance
    let lowerLon = this.state.long - lon * distance

    const upperLat = this.state.lat + lat * distance
    const upperLon = this.state.long + lon * distance

    const lower = geohash.encode(lowerLat, lowerLon)
    const upper = geohash.encode(upperLat, upperLon)

    // Querying the firestore
    var query = firebase
      .firestore()
      .collection("pins")
      .where("geohash", ">=", lower)
      .where("geohash", "<=", upper)
      .orderBy("geohash")
      .orderBy("createdAt", "desc")

    query.onSnapshot(snapshot => {
      this.setState({ pins: [] })
      snapshot.forEach(doc => {
        if (
          doc &&
          doc.data() &&
          doc.data().expiry &&
          doc.data().expiry.toDate() >= new Date()
        ) {
          if (this.state.pins.length < (this.props.limit ? 3 : 1000)) {
            this.setState(
              prevState => {
                const pins = this.state.pins.concat({
                  id: doc.id,
                  text: doc.data().content.text,
                  creatorID: doc.data().creator ? doc.data().creator.id : null,
                  createdAt: doc.data().createdAt,
                  imageSrc: doc.data().content.file,
                  password: doc.data().password ? doc.data().password : "",
                })

                return { pins }
              },
              () => this.getAuthorName(doc.id, doc.data().creator)
            )
          }
        }
      })
    })
  }

  render() {
    var my_pins =
      this.state.pins.length > 0
        ? this.state.pins.map(pin =>
            this.getPinHtml(
              pin.id,
              pin.imageSrc,
              pin.text,
              this.state.pinCreators[pin.id],
              "",
              pin.password
            )
          )
        : ""

    if (my_pins != "") {
      return (
        <>
          <p
            id="pinsError"
            style={{ color: "red" }}
            hidden
            ref={this.pinsError}
          >
            Cannot access logged in user
          </p>
          {my_pins}
        </>
      )
    } else {
      return (
        <div className="offset-md-4 col-md-3">
          <img src={Nopin} className="no-pin-icon"></img>
          <h5 className="text-center"> No pins nearby </h5>
        </div>
      )
    }
  }
}

export default Nearby
